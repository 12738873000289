import {FileInfo, getFileUrl} from "../../api/downloadAPI";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "../store";
import axios, {HttpStatusCode} from "axios";

const name = 'fileDownload';

export const DownloadStatus = {
    READY: 0,
    DOWNLOADING: 1,
    COMPLETED: 2,
    FAILED: 3,
    PAUSED: 4,
    CANCELED: 5,
} as const;

export type DownloadStatus = typeof DownloadStatus[keyof typeof DownloadStatus];

export interface DownloadItem extends FileInfo {
    key: number;
    status: DownloadStatus;
}

interface DownloadState {
    rootDirHandle: FileSystemDirectoryHandle | null;
    items: DownloadItem[];
    status: DownloadStatus;
    errorMessage: string | null;
    currentItemIndex: number;
    totalSize: number;
    currentReadBytes: number;
    totalReadBytes: number;
    currentProgress: number;
    totalProgress: number;
    isPausing: boolean;
    isCanceling: boolean;
}

const initialState: DownloadState = {
    rootDirHandle: null,
    items: [],
    status: DownloadStatus.READY,
    errorMessage: null,
    currentItemIndex: -1,
    totalSize: 0,
    currentReadBytes: 0,
    totalReadBytes: 0,
    currentProgress: 0,
    totalProgress: 0,
    isPausing: false,
    isCanceling: false,
};

export const fileDownloadSlice = createSlice({
    name: `${name}`,
    initialState,
    reducers: {
        initialize: (state) => {
            Object.assign(state, initialState);
        },
        setDownloadItems: (state, action: PayloadAction<DownloadItem[]>) => {
            state.items = action.payload;
            state.totalSize = state.items.reduce((total, x) => x.size + total, 0);
            state.status = DownloadStatus.READY;
            state.currentReadBytes = 0;
            state.totalReadBytes = 0;
        },
        setDownloadStatus: (state, action: PayloadAction<{status: DownloadStatus, message?: string}>) => {
            state.status = action.payload.status;
            state.errorMessage = action.payload?.message ?? null;

            if (state.status === DownloadStatus.COMPLETED) {
                state.totalReadBytes = state.totalSize;
                state.totalProgress = 100;
                state.rootDirHandle = null;
            } else if (state.status === DownloadStatus.PAUSED) {
                state.isPausing = false;
            } else if (state.status == DownloadStatus.CANCELED) {
                state.isCanceling = false;
            }
        },
        setDownloadItemStatus: (state, action: PayloadAction<{ index: number, status: DownloadStatus }>) => {
            const item = state.items[action.payload.index];
            item.status = action.payload.status;
            if (item.status === DownloadStatus.COMPLETED) {
                state.currentReadBytes = item.size;
                state.currentProgress = 100;
            }
        },
        requestPausing: (state) => {
            state.isPausing = true;
        },
        requestCanceling: (state) => {
            if (state.status === DownloadStatus.DOWNLOADING) {
                state.isCanceling = true;
            } else {
                state.status = DownloadStatus.CANCELED;
            }
        },
        setCurrentItemIndex: (state, action: PayloadAction<number>) => {
            state.currentItemIndex = action.payload;
        },
        setCurrentProgress: (state, action: PayloadAction<{ readBytes: number, progress: number }>) => {
            state.currentReadBytes = action.payload.readBytes;
            state.currentProgress = action.payload.progress;
        },
        setTotalProgress: (state, action: PayloadAction<{ readBytes: number, progress: number }>) => {
            state.totalReadBytes = action.payload.readBytes;
            state.totalProgress = action.payload.progress;
        },
        setProgress: (state, action: PayloadAction<{ currentReadBytes: number, totalReadBytes: number, currentProgress: number, totalProgress: number }>) => {
            state.currentReadBytes = action.payload.currentReadBytes;
            state.totalReadBytes = action.payload.totalReadBytes;
            state.currentProgress = action.payload.currentProgress;
            state.totalProgress = action.payload.totalProgress;
        },
        setRootDirHandle: (state, action: PayloadAction<FileSystemDirectoryHandle>) => {
            state.rootDirHandle = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(downloadFile.fulfilled, (state, action) => {

        });
    },
});

export const {
    initialize,
    setDownloadItems,
    setDownloadStatus,
    setDownloadItemStatus,
    requestPausing,
    requestCanceling,
    setCurrentItemIndex,
    setCurrentProgress,
    setTotalProgress,
    setProgress,
    setRootDirHandle,
} = fileDownloadSlice.actions;

export const selectFileDownloadState = (state: RootState) => state.fileDownload;

export default fileDownloadSlice.reducer;

const chunkSize = 1024 * 1024 * 16; // 16 MB

const getFileHandle = async (filePath: string, rootDirHandle: FileSystemDirectoryHandle, isResumed: boolean) => {
    let dirHandle = rootDirHandle;

    const filePathes = filePath.split('/');
    for (let i = 0; i < filePathes.length - 1; i++) {
        dirHandle = await dirHandle.getDirectoryHandle(filePathes[i], {create: true});
    }

    return await dirHandle.getFileHandle(filePathes[filePathes.length - 1], {create: !isResumed});
}

function calcProgress(current: number, total: number): number {
    return Math.min(
        Math.floor(current * 100 / total),
        99 // 100% 방지
    );
}

export const retry = async <T>(
    fn: () => Promise<T> | T,
    {retries, retryIntervalMs}: { retries: number; retryIntervalMs: number, },
    onRetry?: () => void
): Promise<T> => {
    try {
        return await fn()
    } catch (error) {
        if (retries <= 0) {
            throw error
        }
        await sleep(retryIntervalMs)
        if (onRetry) {
            await onRetry()
        }
        return retry(fn, {retries: retries - 1, retryIntervalMs})
    }
}

const sleep = (ms = 0) => new Promise((resolve) => setTimeout(resolve, ms))

const retryOptions = {retries: 3, retryIntervalMs: 1000}

export const downloadFile = createAsyncThunk<void,
    { handle: FileSystemDirectoryHandle },
    { state: RootState, dispatch: AppDispatch }>(
    `${name}/downloadFile`,
    async ({handle: rootDirHandle}, {getState, dispatch}) => {
        const {fileDownload: state} = getState();

        let completedSize = 0;

        for (let itemIndex = 0; itemIndex < state.items.length; itemIndex++) {
            const item = state.items[itemIndex];

            if (item.status === DownloadStatus.COMPLETED) {
                completedSize += item.size;
                continue;
            }

            dispatch(setCurrentItemIndex(itemIndex));

            const isItemResumed
                = item.status === DownloadStatus.PAUSED
                || item.status === DownloadStatus.FAILED
                || item.status === DownloadStatus.CANCELED;

            let writable;

            try {
                const fileHandle = await getFileHandle(item.name!!, rootDirHandle, isItemResumed);

                writable = await fileHandle.createWritable({keepExistingData: isItemResumed});

                let readBytes = 0;
                if (isItemResumed) {
                    const file = await fileHandle.getFile()
                    readBytes = file.size;
                    completedSize += file.size;
                } else {
                    await writable.truncate(0);
                }

                if (readBytes > 0) {
                    await writable.seek(readBytes);
                }

                let fileUrl = await getFileUrl({fldSeq: item.fldSeq, filePath: item.name!!});

                dispatch(setDownloadStatus({status: DownloadStatus.DOWNLOADING}));
                dispatch(setDownloadItemStatus({index: item.key, status: DownloadStatus.DOWNLOADING}));
                dispatch(setCurrentProgress({readBytes: readBytes, progress: calcProgress(readBytes, item.size)}));

                while (readBytes < item.size) {
                    const response = await retry(
                        async () => {
                            return axios({
                                url: fileUrl!.data.url,
                                method: "GET",
                                responseType: "arraybuffer",
                                timeout: 30000, // 30s
                                headers: {
                                    "Range": `bytes=${readBytes}-${Math.min(readBytes + chunkSize - 1, item.size - 1)}`
                                },
                                onDownloadProgress: (progressEvent => {
                                    if (progressEvent.total) {
                                        const currentLoadedBytes = readBytes + progressEvent.loaded;
                                        const totalLoadedBytes = completedSize + progressEvent.loaded;

                                        const currentProgress = calcProgress(currentLoadedBytes, item.size)
                                        const totalProgress = calcProgress(totalLoadedBytes, state.totalSize)

                                        dispatch(setProgress({
                                            currentReadBytes: currentLoadedBytes,
                                            totalReadBytes: totalLoadedBytes,
                                            currentProgress: currentProgress,
                                            totalProgress: totalProgress
                                        }));
                                    }
                                })
                            });
                        },
                        retryOptions,
                        async () => {
                            fileUrl = await getFileUrl({fldSeq: item.fldSeq, filePath: item.name!!});
                        },
                    );

                    if (response.status !== HttpStatusCode.PartialContent) {
                        await writable.close();

                        dispatch(setDownloadItemStatus({index: item.key, status: DownloadStatus.FAILED}));
                        dispatch(setDownloadStatus({status: DownloadStatus.FAILED, message: `파일 다운로드 실패: ${response.status}`}));
                        return;
                    }

                    await writable.write(response!.data);

                    readBytes += response!.data.byteLength;
                    completedSize += response!.data.byteLength;

                    const {fileDownload: currentState} = getState();
                    if (currentState.isPausing) {
                        await writable.close();

                        dispatch(setDownloadStatus({status: DownloadStatus.PAUSED}));
                        dispatch(setDownloadItemStatus({index: item.key, status: DownloadStatus.PAUSED}));
                        return;
                    }

                    if (currentState.isCanceling) {
                        await writable.close();
                        dispatch(setDownloadStatus({status: DownloadStatus.CANCELED}));
                        dispatch(setDownloadItemStatus({index: item.key, status: DownloadStatus.PAUSED}));
                    }
                }

                await writable.close();
                dispatch(setDownloadItemStatus({index: item.key, status: DownloadStatus.COMPLETED}));
            } catch (e) {
                const message = (e instanceof Error) ? e.message : "";

                if (writable) {
                    await writable.close();
                }

                dispatch(setDownloadItemStatus({index: item.key, status: DownloadStatus.FAILED}));
                dispatch(setDownloadStatus({status: DownloadStatus.FAILED, message}));

                throw e;
            }
        }

        dispatch(setDownloadStatus({status: DownloadStatus.COMPLETED}));
    },
);